import { useQuery, useMutation } from 'react-query'
import API from './api'

const getBalances = async (id) => API(true).get(`/portfolios/${id}/balances`)
const getPortfolios = async () => {
    const portfolios = []
    const res = await API(true).get('/portfolios?filters[label][$notContains]=VIP')
    for (const row of res.data.data) {
        const data = { id: row.id, ...row.attributes }
        if (row.attributes.isActive) {
            const balanceRes = await getBalances(row.id)
            data.balances = balanceRes.data.data
        }
        portfolios.push(data)
    }
    return portfolios
}

export const useGetPortfoliosQuery = (canLoad) => useQuery(['portfolios'], getPortfolios, { enabled: canLoad, staleTime: 60000 })


const closePosition = async ({ id, data }) => API(true).post(`/portfolios/${id}/closes`, { data })

export const useClosePositionMutation = () => useMutation(closePosition)