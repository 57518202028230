import * as React from "react";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Stack,
  Button,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useState, useEffect } from "react";

function ClosePositionDialog(props) {
  const { open, data, onClose, onSubmit } = props;
  const [amount, setAmount] = useState("");
  const [pin, setPin] = useState("");

  useEffect(() => {
    if (!data) {
      return;
    }
    const amt = Math.abs(parseFloat(data.positionAmt));
    setAmount(amt);
    setPin("");
  }, [open]);

  if (!data) {
    return onClose();
  }

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = () => {
    onSubmit(amount, pin);
  };

  return (
    <Dialog maxWidth="lg" onClose={handleClose} open={open}>
      <DialogTitle>Close {data.symbol}</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="amount"
            label="Amount"
            name="amount"
            autoComplete="off"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="pin"
            label="Pin"
            type="password"
            id="pin"
            autoComplete="off"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" justifyContent="space-between">
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton onClick={handleSubmit}>Submit</LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default ClosePositionDialog;
