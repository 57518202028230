import axios from 'axios'

let token = null;

export const setToken = (t) => {
    token = t
}

const API = (auth) => {
    const client = axios.create({
        baseURL: process.env.REACT_APP_API_HOST,
        headers: {
            "Content-Type": "application/json",
            ...(auth ? { "Authorization": `Bearer ${token}` } : null)
        }
    })
    return client
}

export default API