import { useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  Grid,
  Container,
  CardContent,
  Card,
  Typography,
  Button,
  Chip,
  CardActions,
  Stack,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import ClosePositionDialog from "../components/ClosePositionDialog";
import {
  useGetPortfoliosQuery,
  useClosePositionMutation,
} from "../api/portfolios";
import { useQueryClient } from "react-query";

const formatCurrency = (val) => {
  return val.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

const Positions = () => {
  const queryClient = useQueryClient();
  const { data: portfolios, refetch } = useGetPortfoliosQuery(true);
  const [showDialog, setShowDialog] = useState(false);
  const [closingPos, setClosingPos] = useState(null);
  const closePositionMutation = useClosePositionMutation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackSuccess, setSnackSuccess] = useState(false);
  const { id } = useParams();
  const portfolio = portfolios?.find((o) => o.id === parseInt(id));
  if (!portfolio) {
    return;
  }

  const positions = portfolio.balances.positions.sort(
    (a, b) => parseFloat(b.unrealizedProfit) - parseFloat(a.unrealizedProfit)
  );

  const closePosition = async (quantity, pin) => {
    setShowDialog(false);
    closePositionMutation.mutate(
      {
        id: portfolio.id,
        data: {
          symbol: closingPos.symbol,
          leverage: parseInt(closingPos.leverage),
          quantity: parseFloat(quantity),
          pin,
          isLong: closingPos.side === "long",
        },
      },
      {
        onSuccess: (res) => {
          setSnackSuccess(res ? true : false);
          setSnackbarMessage(res ? "Position closed" : "Something went wrong");
          setSnackbarOpen(true);
          queryClient.invalidateQueries("portfolios");
          refetch();
        },
        onError: (err) => {
          setSnackSuccess(false);
          setSnackbarMessage(err.message);
          setSnackbarOpen(true);
        },
      }
    );
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" component="div">
          Positions for {portfolio.label}
        </Typography>
        <Link to="/dashboard">Back</Link>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Symbol</TableCell>
              <TableCell align="right">Profit</TableCell>
              <TableCell align="right">Size</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {positions.map((row) => {
              const profit = parseFloat(row.unrealizedProfit);
              const isLong = row.side === "long";
              return (
                <TableRow
                  key={row.symbol}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Button
                        onClick={() => {
                          setClosingPos(row);
                          setShowDialog(true);
                        }}
                        variant="text"
                        color="inherit"
                      >
                        {row.symbol}
                      </Button>
                      <Chip
                        variant="outlined"
                        color={row.isolated ? "warning" : "primary"}
                        size="small"
                        label={`${row.leverage}x`}
                      />
                      <Chip
                        variant="outlined"
                        color={isLong ? "success" : "error"}
                        size="small"
                        label={`${isLong ? "long" : "short"}`}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell align="right">
                    <Typography color={profit > 0 ? "success" : "error"}>
                      {formatCurrency(profit)} (
                      {Math.round(
                        (profit / parseFloat(row.initialMargin)) * 100
                      )}
                      %)
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    {formatCurrency(parseFloat(row.initialMargin))}
                  </TableCell>
                  <TableCell align="right">{row.positionAmt}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <ClosePositionDialog
        open={showDialog}
        data={closingPos}
        onClose={() => setShowDialog(false)}
        onSubmit={(amount, pin) => closePosition(amount, pin)}
      />
      <Snackbar open={snackbarOpen} autoHideDuration={6000}>
        <Alert severity={snackSuccess ? "success" : "error"}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Positions;
