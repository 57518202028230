import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import UserProvider from './contexts/userContext'
import Router from './Router'

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
          <div className="App">
            <Router />
          </div>
      </UserProvider>
    </QueryClientProvider>
  );
}

export default App;
