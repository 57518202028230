import React from "react";
import axios from "axios";
import {
  Container,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
  CssBaseline,
  Paper,
  Snackbar,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLoginMutation } from "../api/login";
import { useState, useContext, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import { UserContext } from "../contexts/userContext";

const login = async (credentials) => {
  // Simulate an API request to authenticate the user
  // Replace this with your actual API endpoint
  const response = await axios.post("/api/login", credentials);
  return response.data;
};

const Login = () => {
  const { user } = useContext(UserContext);
  const mutation = useLoginMutation();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { login } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/dashboard");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { email, password } = e.target.elements;
    mutation.mutate(
      { identifier: email.value, password: password.value },
      {
        onSuccess: (data) => {
          setLoading(false);
          login(data.data.jwt, data.data.user);
          navigate("/dashboard");
        },
        onError: (err) => {
          setLoading(false);
          setSnackbarMessage(`Login failed`);
          setSnackbarOpen(true);
        },
      }
    );
  };

  return (
    <Container component="main" maxWidth="lg">
      <Box
        sx={{
          marginTop: 8,
        }}
      >
        <Grid container>
          <CssBaseline />
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage:
                "url(https://source.unsplash.com/random/?technology+abstract)",
              backgroundRepeat: "no-repeat",
              backgroundColor: (t) =>
                t.palette.mode === "light"
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Login
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <LoadingButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  loading={loading}
                >
                  Login
                </LoadingButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Snackbar open={snackbarOpen} autoHideDuration={6000}>
        <Alert severity="error">{snackbarMessage}</Alert>
      </Snackbar>
    </Container>
  );
};

export default Login;
