import { createContext, useState, useMemo } from 'react'
import { setToken } from '../api/api'

export const UserContext = createContext();

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);

    useMemo(() => {
        const user = localStorage.getItem("user")
        if (user) {
            setUser(JSON.parse(user))
            setToken(localStorage.getItem("token"))
        }
    }, [])

    const login = (tokenData, userData) => {
        setUser(userData);
        setToken(tokenData)
        localStorage.setItem("user", JSON.stringify(userData))
        localStorage.setItem("token", tokenData)
    };

    const logout = () => {
        setUser(null)
        setToken(null)
        localStorage.clear()
    };

    return (<UserContext.Provider value={{ user, login, logout }}>
        {children}
    </UserContext.Provider>)
}

export default UserProvider