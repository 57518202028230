import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../contexts/userContext";
import {
  Grid,
  Container,
  CardContent,
  Card,
  Typography,
  Button,
  CardActions,
  Stack,
} from "@mui/material";
import { useGetPortfoliosQuery } from "../api/portfolios";

const formatCurrency = (val) => {
  return val.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

const PortfolioStats = ({ data }) => {
  console.log(data)
  const totalUnrealizedProfit = data.balances.totalUnrealizedProfit;
  const totalWalletBalance = data.balances.totalWalletBalance;
  const totalBalance = totalWalletBalance + totalUnrealizedProfit;
  const deployed =
    totalWalletBalance - parseFloat(data.balances.availableBalance);
  const performance = totalBalance - data.capital;
  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          color={performance >= 0 ? "success" : "error"}
          variant="h5"
          component="div"
        >
          {formatCurrency(totalBalance)}
        </Typography>
      </Stack>
      <Typography
        color={performance >= 0 ? "success" : "error"}
        component="div"
      >
        Profit:{" "}
        {`${formatCurrency(totalBalance - data.capital)} (${Math.round(
          (performance / data.capital) * 100
        )})%`}
      </Typography>
      <Typography color={totalUnrealizedProfit >= 0 ? "success" : "error"}>
        Float: {`${formatCurrency(totalUnrealizedProfit)} `}
      </Typography>
      <Typography>
        Deployed:{" "}
        {`${formatCurrency(deployed)} (${Math.round(
          (deployed / totalWalletBalance) * 100
        )}%)`}
      </Typography>
    </Stack>
  );
};

const Dashboard = () => {
  const { user, logout } = useContext(UserContext);
  const { data, refetch } = useGetPortfoliosQuery(true);
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="div">
        Welcome, {user.username}
      </Typography>
      <Button onClick={refetch}>Reload</Button>
      <Grid container spacing={3} mt={1} sx={{ width: "100%" }}>
        {data?.map((row) => (
          <Grid item xs={6} md={4}>
            <Card variant="outlined">
              <CardContent>
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {row.label}
                  </Typography>
                  <Typography component="div">
                    {formatCurrency(row.capital)}
                  </Typography>
                </Stack>
                {row.isActive ? (
                  <PortfolioStats data={row} />
                ) : (
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Inactive
                  </Typography>
                )}
              </CardContent>
              {row.isActive && (
                <CardActions>
                  <Link to={`/positions/${row.id}`}>View</Link>
                </CardActions>
              )}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Dashboard;
